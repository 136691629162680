
/* DADOS PESSOAIS */
.container-principal-tela-checkout{
        width: 100vw;
        height: 100vh;
        background-color: var(--cor-gelo);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        overflow: scroll;
}
.div-imagem-checkout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 150px;
    align-self: center;
}

.container-form-checkout{
    align-self: center;
    width: 100%;
    background-color: white;
    border-radius: var(--raio-borda);
    box-shadow: var(--sombra);;
}

.secao-form-botao-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px;
    margin-top: 10px;
}

.tabela-checkout{
    display: flex;
    flex-direction: column;
    width: calc(100vw - 20vw);
    gap: 10px;
    margin-top: 20px;
}


.titulo-container-checkout{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin: 20px 20px 20px;
    padding: 10px;
}

.container-secao-form-checkout{
    padding: 20px;
    width: 100%;
}

.secao-form-inputs-checkout{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    border: 1px solid rgb(119, 118, 118);
    padding: 10px;
}

.secao-chekout-container-input{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:100%;
    margin-top: 10px;
}


.titulo-container-checkout h1{
    font-size: 20px;
}

.div-para-alinhar{
display: none;
}
/* TELA EQUIPAMENTO */

.container-principal-equipamentos{
width: 100%;
margin-top: 30px;
display: flex;
justify-content: center;
flex-direction: row;
}

/* .container-secundario-equipamentos{
background-color: red;
} */

.equipamentos-lista{
    width: 200px;
    height: 200px;
    margin: 20px;
}


/* TELA TIPO SERVICO */

.container-principal-tipoServico{
width: 100%;
margin-top: 50px;
display: flex;
justify-content: center;
flex-direction: row;
}


.btn-instalacao{
    width: 300px;
    height: 100px;
    margin: 10px;
    background-color: transparent;
    text-align: center;
    border: 1px solid orange;
    color: orange;
    border-radius: 500px;
}

.btn-manutencao{
    width: 300px;
    height: 100px;
    margin: 10px;
    background-color: transparent;
    text-align: center;
    border: 1px solid blue;
    color: blue;
    border-radius: 500px;
}


/* TELA LISTA EQUIPAMENTO */

.container-principal-lista-equipamento{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.div-menu-selecao-aparelho{
    width: 500px;
}

.div-img{
  background-repeat: no-repeat;
  background-size: auto;
  width: 200px;
  height: 300px;
}



/* TELA FABRICANTES */

.container-resumo-tela-fabricante{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 20px;
gap: 30px;
}

.container-resumo-tela-fabricante h1{
    font-size: 20px;
}
.div-img-item-tela-fabricante{
    width: 100px;
    height: 150px;
}

.container-fabricantes-tela-fabricante{
 width: 100%;
 padding: 30px;
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;
 align-items: center;
}

.btn-fabricantes-tela-fabricante{
    margin-top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: transparent;
    width: 260px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid black;
}

.div-bt-image-fabricante-tela-fabricante{
    border-radius: 500px;
    width: 80px;
    height: 80px;
}


/* VERSAO MOBILE */

@media (max-width: 823px) {

    .container-principal-tela-checkout{
        position: none;
        z-index: none;
        padding: 0;
    }
    .container-form-checkout{
    margin-top: 30px;    
    width: 100vw;
    height: 'auto';
    }


/* TELA EQUIPAMENTOS */

.container-principal-equipamentos{
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    }
    .equipamentos-lista{
        width: 100px;
        height: 100px;
        margin: 10px;
    }


/* TELA TIPO DE SERVICO */
.container-principal-tipoServico{
width: 100%;
margin-top: 50px;
display: block;
justify-content: center;
flex-direction: row;
}


.btn-instalacao{
    width: 90%;
    height: 60px;
    background-color: transparent;
    text-align: center;
    border: 1px solid orange;
    color: orange;
    border-radius: 500px;
}

.btn-manutencao{
    width: 90%;
    height: 60px;
    margin: 10px;
    background-color: transparent;
    text-align: center;
    border: 1px solid blue;
    color: blue;
    border-radius: 500px;
}

/* TELA LISTA EQUIPAMENTO */

.container-principal-lista-equipamento{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.div-menu-selecao-aparelho{
    width: 200px;
}

.div-img{
  background-repeat: no-repeat;
  background-size: auto;
  width: 100px;
  height: 200px;
}


/* TELA FABRICANTES */
    
    .container-fabricantes-tela-fabricante{
     width: 100%;
     display: grid;
     grid-template-columns: 1fr 1fr;
     gap: 10px;
    }
    
    .btn-fabricantes-tela-fabricante{
        justify-content: center;
        display: flex;
        align-items: center;
        background-color: transparent;
        width: 150px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid black;
    }
    
    .div-bt-image-fabricante-tela-fabricante{
        border-radius: 500px;
        width: 50px;
        height: 50px;
    }


    /* DADOS PESSOAIS */

    .secao-chekout-container-input{
        padding-left: 10px;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: 1fr;
    }
 }

 @media (max-width: 1580px){
    .secao-chekout-container-input{
        background-color: red;
        display: flex;
        flex-wrap: wrap;
    }
.div-para-alinhar{
    display: block;
    width: 300px; height: 60px
}
 }