.sistema-header-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: var(--raio-borda-container);
    padding: 0 20px;
    box-shadow: var(--sombra);
}

.sistema-header-logo-principal {
    width: 150px;
    padding: 50px;
}

.sistema-header-nav-direita {
    width: 150px;
    display: flex;
    justify-content: right;
}

.sistema-header-nav {
    display: flex;
    gap: 40px;
}

.sistema-header-nav-link {
    color: var(--cor-secundaria);
    font-weight: bold;
}