.sistema-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--cor-gelo);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    overflow: scroll;
    position: 'relative';
    z-index: 1;
}