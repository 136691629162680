.container-secao-form-checkout{
    padding: 20px;
    width: 100%;
}

.secao-form-inputs-checkout{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    border: 1px solid rgb(119, 118, 118);
    padding: 10px;
}

.secao-chekout-container-input{
     display:flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
    width:100%;
    margin-top: 10px;
}
