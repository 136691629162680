.sidebar-container {
    width: 20vw;
    height: 100%;
    background-color: white;
    padding: 10px;
    box-shadow: var(--sombra);
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: var(--raio-borda-container);
    overflow: hidden;
    position: relative;;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 300px;
    background-position-x: center;
}

.bgi-dashboard {
    background-image: url(../../../../assets/image/dashboardpromo.png);
}

.bgi-pedido {
    background-image: url(../../../../assets/image/sistemapromo.png);
    background-size: 400px;
    background-position-y: calc(100% + 60px);
}

.bgi-cliente {
    background-image: url(../../../../assets/image/clientepromo.png);
}

.bgi-financeiro {
    background-image: url(../../../../assets/image/financeiropromo.png)
}

.bgi-perfil {
    background-image: url(../../../../assets/image/perfilpromo.png)
}