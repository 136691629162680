.termoo-de-usoo-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.termoo-de-usoo-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 20vw);
    gap: 30px;
    margin-top: 70px;
}

.termoo-de-usoo-titulos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.h1-termo-de-uso {
    font-size: x-large;
}

.h2-termo-de-uso {
    display: flex;
    font-size: larger;
    text-align: center;
}
.center{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

}

.p-termo-de-uso {
    
}

.conheça-onework {
    display: flex;
    flex-direction: column;
   
}

.definicoes {
}

.aplicabilidade {
}

.Serviços {
}

.regras {
}
.responsabilidades {
}

.Garantias {
}

.sançoes {
}

.propriedades {
}

.tempo {
}
.usuario {
}
.regras-gerais {
}
.resolvemos-problemas {
}

