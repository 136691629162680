.agenciamento-de-serviços-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.agenciamento-de-serviços-wrapper{
    display: flex;
    flex-direction: column;
    width: calc(100vw - 20vw);
    gap: 30px;
    margin-top: 70px;
}

.agenciamento-de-serviços-titulos{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.h1-agenciamento-de-serviços{
    font-size: x-large;
}
.h2-agenciamento-de-serviços{
    display: flex;
    font-size: larger;
    text-align: center;
}

.center{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

}

.definiçoes{

}

.objeto{

}

.adesao{

}

.NÃO-EXCLUSIVIDADE{

}

.execuçao-de-serviços{

}
.PAGAMENTO{

}

.direito-propriedade{

}
.vigencia{

}

.RESPONSABILIDADES{

}

.alteraçao-contrato{

}

.proteçao-dados{

}

.disposiçoes-gerais{

}
.contato{

}

.PENALIDADES{

}
.legislaçao-aplicavel{
    
}