/* CERTIFICADO ENSINO MEDIO */
.ctd-container-geral {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.box-texto-esquerda {
    padding: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    gap: 5px
}

.ctd-ensino-medio-container-conteudo {
    max-width: 1100px;
    min-width: 500px;
    min-height: 500px;
    max-height: 900px;
}

.ctd-ensino-medio-div-texto-titulo {
    width: 100%;
    text-align: center;
}

.ctd-ensino-medio-div-texto-titulo h1 {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 35px;
}

.ctd-ensino-medio-div-texto-titulo h2 {
    font-size: 15px;
}

.ctd-ensino-medio-div-texto-titulo p {
    font-family: 18px;
}

.ctd-ensino-medio-div-container-nome-aluno {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    display: block;
}

.ctd-ensino-medio-div-nome-aluno {
    margin-top: 50px;
    text-align: center;
    border-bottom: 1px solid black;
    width: 100%;
}

.ctd-ensino-medio-div-nome-aluno h1 {
    font-size: 40px;
}

.ctd-ensino-medio-container-conteudo-dados {
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    max-width: 800px;
    text-transform: uppercase;
}

.ctd-ensino-medio-container-conteudo-dados p {
    padding-left: 10px;
    font-size: 18px;
}

.ctd-ensino-medio-container-conteudo-dados strong {
    padding-left: 10px;
    font-size: 18px;
}

.ctd-ensino-medio-container-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    margin-top: 50px;
    text-align: center;
    text-transform: uppercase;
}

.ctd-ensino-medio-container-data p {
    font-size: 18px;
    font-weight: bold;
}

.ctd-ensino-medio-container-assinaturas {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 100px;
}

.ctd-ensino-medio-linha-assinatura {
    border-top: 1px solid black;
    width: 200px;
    text-align: center;
}

.ctd-ensino-medio-linha-assinatura p {
    font-size: 18px;
    font-weight: bold;
}


/* TELA VERSO DIPLOMA TECNICO */

.verso-ctd-tecnico-container {
    max-width: 1000px;
    min-width: 500px;
    height: auto;
    display: grid;
    border: 1px solid black;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template: "header1 header2 header3" 90px "conteudoMateria conteudoEstabelecimento conteudoDocumento" 70px "conteudoMateria conteudoLocalidade conteudoDocumento" 70px "conteudoMateria conteudoPerfilProfissional conteudoDocumento"
        "conteudoObservacao conteudoPerfilProfissional conteudoDocumento" 80px "conteudoEstagio  conteudoVazio ConteudoVazio" 30px "conteudoTotal conteudoVazio ConteudoVazio" 30px;
}

/* TEXTOS */
.box-documento-texto {
    text-align: left;
}

.verso-ctd-titulo-menor {
    font-size: 12px;
}

.verso-ctd-titulo-medio {
    font-size: 14px;
}

.verso-ctd-titulo-maior {
    font-size: 16px;
}

/* GRIDS */
.verso-ctd-linha-header1 {
    padding: 10px;
    text-align: center;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    grid-area: header1;
}

.verso-ctd-linha-header2 {
    padding: 7px;
    text-align: center;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    grid-area: header2;
}

.verso-ctd-linha-header3 {
    padding: 10px;
    border-bottom: 1px solid black;
    grid-area: header3;
}


.verso-ctd-linha-materia-nota {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    grid-area: conteudoMateria;
    display: grid;
}

.verso-ctd-box-texto-materia {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 6px;
}

.verso-ctd-texto-materia {
    width: 80%;
}

.verso-ctd-texto-nota-materia {
    width: 15%;
    border-left: 1px solid black;
    text-align: right;
}

.verso-ctd-linha-estabelecimento {
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    text-align: center;
    grid-area: conteudoEstabelecimento;
}


.verso-ctd-linha-documento-sem-borda {
    border-bottom: 1px solid black;
    grid-area: conteudoDocumento;
}

.box-linha {
    margin-top: 50%;
    width: 100%;
    display: flex;
    height: 3px;
    justify-content: center;
}

.box-documento-footer {
    margin-top: 40%;
    text-align: center;
}

.linha-grande {
    width: 50%;
    height: 1px;
    background-color: black;
}

.box-documento-footer h1 {
    margin-top: 20px;
}

.verso-ctd-linha-localidade {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    grid-area: conteudoLocalidade;
}

.verso-ctd-linha-perfil-profissional {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    grid-area: conteudoPerfilProfissional;
    padding: 10px;
}

.box-texto-perfil-profissional {
    width: 100%;
    text-align: left;
}

.verso-ctd-linha-observacoes {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    grid-area: conteudoObservacao;
    text-align: center;
}


.verso-ctd-linha-estagio {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    grid-area: conteudoEstagio;
}

.verso-ctd-box-texto-estagio {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.verso-ctd-texto-estagio {
    width: 80%;
    font-size: 16px;
}

.verso-ctd-linha-total-geral {
    border-right: 1px solid black;
    grid-area: conteudoTotal;
}





/* VERSO ENSINO MEDIO */
.container-grid-verso1-em {
    width: 1000px;
    height: 700px;
    border-collapse: collapse;
    border: 1px solid black;
    display: grid;
    grid-template-areas: "area1 area2"
        "area3  area2"
        "area4  area2";
    grid-template-columns: 4fr 2fr;
    grid-template-rows: 1fr 0.1fr 4fr;
}


.container-area1 {
    grid-area: area1;
    display: grid;
    grid-template-areas: "sub-area1-1"
        "sub-area1-2";
    grid-template-columns: 100% 100%;
    grid-template-rows: 80px;
}

.sub-container-area1-1 {
    grid-area: sub-area1-1;
    display: grid;
    grid-template-areas: "curso-anterior ano-conclusao instituicao-ensino localidade"
        "curso-anterior ano-conclusao2 instituicao-ensino2 localidade2"
    ;
    grid-template-columns: 1.4fr 1fr 2fr 2fr;
    grid-template-rows: repeat(7, 40px);
}

.sub-container-area1-1-conteudo-1 {
    grid-area: curso-anterior;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.sub-container-area1-1-conteudo-2 {
    grid-area: ano-conclusao;
    align-items: center;
    padding: 2px;
    flex-wrap: wrap;
    text-align: center;
    border-right: 1px solid black;
}

.sub-container-area1-1-conteudo-3 {
    grid-area: instituicao-ensino;
    align-items: center;
    padding-top: 7px;
    flex-wrap: wrap;
    text-align: center;
    border-right: 1px solid black;
}

.sub-container-area1-1-conteudo-4 {
    grid-area: localidade;
    align-items: center;
    padding-top: 7px;
    flex-wrap: wrap;
    text-align: center;
}

.sub-container-area1-1-conteudo-5 {
    grid-area: ano-conclusao2;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    align-items: center;
    padding-top: 5px;
    flex-wrap: wrap;
    text-align: center;
}

.sub-container-area1-1-conteudo-6 {
    grid-area: instituicao-ensino2;
    flex-wrap: wrap;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.sub-container-area1-1-conteudo-7 {
    grid-area: localidade2;
    align-items: center;
    padding-top: 5px;
    flex-wrap: wrap;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}




.sub-container-area1-2 {
    grid-area: sub-area1-2;
    display: grid;
    grid-template-areas:
        "curso-anterior-area1-2 ano-conclusao-area1-2 instituicao-ensino-area1-2 localidade1-area1-2"
        "curso-anterior-area1-2 ano-conclusao-area1-3 instituicao-ensino-area1-3 localidade-area1-3";
    grid-template-columns: 1.4fr 1fr 2fr 2fr;
    grid-template-rows: 0.2fr 1fr;
}

.sub-container-area1-2-conteudo-1 {
    grid-area: curso-anterior-area1-2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.sub-container-area1-2-conteudo-2 {
    grid-area: ano-conclusao-area1-2;
    align-items: center;
    padding: 2px;
    flex-wrap: wrap;
    text-align: center;
    border-right: 1px solid black;
}

.sub-container-area1-2-conteudo-3 {
    grid-area: instituicao-ensino-area1-2;
    align-items: center;
    padding-top: 7px;
    flex-wrap: wrap;
    text-align: center;
    border-right: 1px solid black;
}

.sub-container-area1-2-conteudo-4 {
    grid-area: localidade1-area1-2;
    align-items: center;
    padding-top: 7px;
    flex-wrap: wrap;
    text-align: center;
}

.sub-container-area1-2-conteudo-5 {
    grid-area: ano-conclusao-area1-3;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.sub-container-area1-2-conteudo-6 {
    grid-area: instituicao-ensino-area1-3;
    flex-wrap: wrap;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.sub-container-area1-2-conteudo-7 {
    grid-area: localidade-area1-3;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}



.container-area2 {
    grid-area: area2;
    border-left: 1px solid black;
    display: block;
    justify-content: center;
}

.box-area2-documento {
    text-align: left;
}

.box-area2-censo-escolar {
    margin-top: 100%;
    text-align: center;
}

.container-area3 {
    grid-area: area3;
    text-align: center;
    border-bottom: 1px solid black;
}

.container-area4 {
    grid-area: area4;
    display: grid;
    grid-template-areas: "area4-coluna1 area4-coluna2";
    grid-auto-columns: 1fr 1fr;
}

.sub-container-area4-1 {
    grid-area: area4-coluna1;
    border-right: 1px solid black;
    display: grid;
    grid-template-areas: "conteudo-header"
        "conteudo-conteudo";
    grid-template-rows: 0.1fr 1fr;
}

.conteudo-header-area4 {
    grid-area: conteudo-header;
}

.box-conteudo-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.conteudo-box-header1 {
    width: 70%;
    text-align: center;
    border-bottom: 1px solid black;
}

.conteudo-box-header2 {
    width: 30%;
    border-left: 1px solid black;
    text-align: center;
    border-bottom: 1px solid black;
}

.conteudo-conteudo-area4 {
    grid-area: conteudo-conteudo;
}

.box-conteudo-conteudo {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.conteudo-box-conteudo1 {
    width: 70%;
    text-align: left;
    border-bottom: 1px solid black;
}

.conteudo-box-conteudo2 {
    width: 30%;
    border-left: 1px solid black;
    text-align: center;
    border-bottom: 1px solid black;
}


.sub-container-area4-2 {
    grid-area: area4-coluna2;
    display: grid;
    grid-template-areas: "conteudo-area4-2-1"
        "conteudo-area4-2-2"
        "conteudo-area4-2-3"
        "conteudo-area4-2-4"
        "conteudo-area4-2-5";
    grid-template-rows: 91px 0.1fr 91px 0.1fr 1fr;
}


.container-conteudo-area4-1 {
    grid-area: conteudo-area4-2-1;
    display: block;
    text-align: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid black;
}

.container-conteudo-area4-2 {
    grid-area: conteudo-area4-2-2;
    padding: 1.3px;
    text-align: center;
}

.container-conteudo-area4-3 {
    grid-area: conteudo-area4-2-3;
    border-top: 1px solid black;
    display: block;
    text-align: center;
    justify-content: center;
    padding: 10px;
}

.container-conteudo-area4-4 {
    border-top: 1px solid black;
    grid-area: conteudo-area4-2-4;
    padding: 1.3px;
    text-align: center;
}

.container-conteudo-area4-5 {
    border-top: 1px solid black;
    grid-area: conteudo-area4-2-5;
    display: block;
    padding: 10px;
}

.container-conteudo-area4-5 h1 {
    text-decoration: underline;
}

/* FIM DO VERSO ENSINO MEDIO */





/* HISTORICO ESCOLAR ENSINO MEDIO */
.container-grid-verso-historico-escolar-em {
    display: grid;
    border: 1px solid black;
    width: 80vw;
    max-height: 90vh;
    grid-template-areas: "container-verso-historico-escola-em1 container-verso-historico-escola-em2"
        "container-verso-historico-escola-em1 container-verso-historico-escola-em3";
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
}

.conteudo-verso-historico-escolar-em1 {
    grid-area: container-verso-historico-escola-em1;
    border-right: 1px solid black;

}

.conteudo-verso-historico-escolar-em2 {
    grid-area: container-verso-historico-escola-em2;
    border-bottom: 1px solid black;
    padding: 5px;
    text-align: justify;
}

.conteudo-verso-historico-escolar-em3 {
    grid-area: container-verso-historico-escola-em3;
    padding: 5px;
    text-align: justify;
}

.linha-assinatura{
    border-top: 1px solid black;
    width: 160px;
}
.verso-historico-escolar-em-final {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.verso-historico-escolar-em-final-conteudo {
    text-align: center;
    display: flex;
    width: 60%;
    justify-content: space-between;
}

/* HISTORICO ESCOLAR TEC */
.texto-underline {
    text-decoration: underline;
    font-size: 14px;
}

.texto-pequeno {
    font-size: 12px;
}

.texto-medio {
    font-size: 14px;
}

.texto-grande {
    font-size: 16px;
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.historico-container {
    width: 900px;
    height: auto;
    background-color: white;
}

.box-centralizador {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.box-titulo1-historico {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.box-borda-quadrada {
    border: 1px solid black;
    text-align: center;
    width: 150px;
    position: absolute;
    margin-left: 24%;
    margin-top: 5px;
    padding: 3px;
}

.box-titulo1-historico h1 {
    font-size: 16px;
    text-decoration: underline;
}


.box-texto-linha {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.box-texto-linha-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}

.secao-2 {
    margin-top: 20px;
}



/* TABELA */

.container-tabela {
    width: 100%;
    margin-top: 30px;
}

.tabela-historico-tec {
    width: 100%;
}

.tabela-historico-tec-head {
    display: flex;
}

.tabela-historico-tr-head {
    display: flex;
    width: 100%;
}

.tabela-historico-tec-th-head-1 {
    text-align: center;
    padding-top: 5px;
    border: 1px solid black;
}

.tabela-historico-tec-th-head-2 {
    text-align: center;
    padding-top: 5px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.tabela-historico-tec-body {
    display: block;
}

.tabela-historico-tec-tr-body {
    width: 100%;
    display: flex;
}

.tabela-historico-tec-td-body-1 {
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

.tabela-historico-tec-td-body-2 {
    text-align: center;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabela-historico-tec-td-body-3 {
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

.tabela-historico-tec-td-body-0 {
    border-bottom: 1px solid black;
}

.tabela-historico-tec-td-body-4 {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid black;
}

.tabela-historico-paragrafo {
    font-size: 14px;
    border-bottom: 1px solid black;
}



/* VERSO HISTORICO */
.corpo-texto {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    padding: 10px;
}